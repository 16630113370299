import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/shared/services/api.service";
import { AuthUserService } from "src/app/shared/services/auth-user.service";
import { TopNavigationEventsService } from "src/app/shared/services/top-navigation-events.service";
@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"],
})
export class CompanyComponent implements OnInit, OnDestroy {
  companies = [];
  routerEventChangeSubscription: Subscription = null;
  selectedCompanyId = 0;
  selectedCompanyName = '';
  constructor(
    private api: ApiService,
    private router: Router,
    private _topNavigationEventsService: TopNavigationEventsService,
    private _authService: AuthUserService) { }

  ngOnInit() {
    this.companies = [
      { id: 0, name: `All` }
    ];
    this.routerEventChangeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const user = this._authService.getUser();
        let allowedUrls = ['/address','/dashboard','/user/profile'];
        if((allowedUrls.includes(event.url) || event.url.startsWith('/orders')) && user){
          this.selectedCompanyId = 0;
          this.selectedCompanyName = 'All';
          this.getCompaniesOfUser();
        }
      }
    });
    this.selectedCompanyName = 'All';
    this.getCompaniesOfUser();
  }
  
  ngOnDestroy(): void {
    this.routerEventChangeSubscription.unsubscribe();
  }

  getCompaniesOfUser() {
    this.api.request("GET_COMPANIES_OF_USER").subscribe((res) => {
      this.companies = [
        { id: 0, name: `All` },
      ];
      const user = this._authService.getUser();
      if (user) {
        this.companies.push({ id: -1, name: `${user.firstName} ${user.lastName}` });
      }
      res["result"].forEach(company => {
        this.companies.push(company);
      });
    });
  }

  onCompanySelectionChanged(company) {
    this._topNavigationEventsService.loadOrdersOnCompanyChanges.next([company.id]);
    this.selectedCompanyName = company.name;
  }
}