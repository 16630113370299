<div >
    <mat-tab-group (selectedTabChange)="selectSheet($event)">
      <mat-tab [label]="sheetName" *ngFor = "let sheetName of sheetNames;let sheetIndex = index"> 
        
          <table > 
            <tbody class="excel_table">
              <tr>
                <th>Sr. No</th>
                <th *ngFor="let headerValue of CurrentWorkSheetHeader" class="excel_table_cell">
                  {{headerValue}}
                </th>
              </tr>
              <tr *ngFor="let row of CurrentWorkSheetData;let i = index">
                <td>{{i + 1}}</td>
                <td *ngFor="let val of row" class="excel_table_cell">
                  {{val}}
                </td>
              </tr>
            </tbody>
          </table>
         
      </mat-tab>
    </mat-tab-group>
  </div>
    
  
  
  
  
  