import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { LoadActiveUser } from 'src/app/features/account/state/account.actions';
import { ClearAddresses } from 'src/app/features/address/state/address.actions';
import { ClearOrders } from 'src/app/features/orders/state/order.action';
import { AuthUserService } from '../../services/auth-user.service';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {

  firstName='';
  lastName='';
  addressState = null;

  constructor(private router: Router,
    private auth: AuthUserService,
    private actions$: Actions,
    private store: Store) { }

  ngOnInit() {
    this.getActiveUser();
    this.actions$.pipe(ofActionSuccessful(LoadActiveUser))
    .subscribe(data => {this.getActiveUser() });
  }

  getActiveUser() {
    const user = this.auth.getUser();
    if (user) {
      this.firstName = user.firstName;
      this.lastName = user.lastName;
    } else {
      this.signOff();
    }
  }

  OnProfileClick(){
    this.router.navigate([`/user/profile`]);
  }

  OnDashboardClick(){
    this.router.navigate([`/dashboard`]);
  }

  OnAddressClick(){
    this.router.navigate([`/address`]);
  }
  
  signOff(){
    this.router.navigate(['/account/login']);
    this.store.dispatch(new ClearAddresses());
    this.store.dispatch(new ClearOrders());
    this.auth.clear();
  }
}
