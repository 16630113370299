import { Address } from 'src/app/shared/interfaces/address'

export  class LoadAddress {
    static readonly type = '[Address] Load Address'
    constructor(){}
}

export  class LoadAddressSuccessfully {
    static readonly type = '[Address] Load Address Successfully'
    constructor(){}
}

export  class AddAddress {
    static readonly type = '[Address] Load Address'
    constructor(public address:Address){}
}

export  class UpdateAddress {
    static readonly type = '[Address] Update Address'
    constructor(public address:Address){}
}

export  class DeleteAddress {
    static readonly type = '[Address] Delete Address'
    constructor(public id:number, public type:string){}
}

export  class ClearAddresses {
    static readonly type = '[Address] Clear Addresses'
    constructor(){}
}