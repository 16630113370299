import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company } from 'src/app/shared/interfaces/company';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthUserService } from 'src/app/shared/services/auth-user.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.scss']
})
export class CompanyCreateComponent implements OnInit {

  disabled = false;
  addressTypeValidate = false;
  addedCompany : Company;

  form = this.formBuilder.group({
    name: [''],
    website: [''],
    email: [''],
    phone: [''],
    altPhone: [''],
    isBypassPayment : [false],
    isTaxExempt : [false],
    sourceType : ['CUSTOMER_PORTAL'],
    addToUsersCompany : [true],
    billingAddress : this.formBuilder.group({
      addressLine1: [''],
      addressLine2: [''],
      state: [''],
      city: [''],
      postalZip: [''],
      country: [''],
    }),
    shippingAddress : this.formBuilder.group({
      addressLine1: [''],
      addressLine2: [''],
      state: [''],
      city: [''],
      postalZip: [''],
      country: [''],
    })
  });

  constructor(public formBuilder: UntypedFormBuilder,
    private api: ApiService,
    public dialogRef: MatDialogRef<CompanyCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public company: Company,
    private toast:ToastService,
    private auth : AuthUserService) { }

  ngOnInit() {
  }

  hasError = (field: string, errorName: string): boolean =>
    this.form.get(field).errors ? this.form.get(field).errors[errorName] : false

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.create();
  }

  hasErrorInGroup(group: string, field: string, errorName: string): Boolean {
    if (this.form.get(group).get(field).errors) {
      if (this.form.get(group).get(field).errors[errorName]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private create() {
    this.api.request('CREATE_COMPANIES', { data: this.form.value }).subscribe((res: any) => {
      if(res['success']){
        this.toast.show(res['message']);
        
        this.addedCompany = {
          id : res['result'],
          name : this.form.value['name'],
          website : this.form.value['website'],
          email : this.form.value['email'],
          phone : this.form.value['phone'],
          isBypassPayment : this.form.value['isBypassPayment'],
          isTaxExempt : this.form.value['isTaxExempt'],
          sourceType : this.form.value['sourceType'],
          billing : null,
          shipping : null
        };
        
        this.dialogRef.close(this.addedCompany);
      }
    });
  }


  closeDialog(){
    this.dialogRef.close(null);
  }
}
