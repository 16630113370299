import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  menuItems =[
    // { title: 'DASHBOARD', icon: 'dashboard', route: '/dashboard' },
    { title: 'ORDERS', icon: 'local_shipping', route: '/orders' },
    { title: 'ADDRESS', icon: 'chrome_reader_mode', route: '/address' },];
    
  constructor() { }

  ngOnInit() {
  }

}
