export class GetNotification {
    static readonly type = '[Notification] Get Notification';
    constructor(public notification) {}
}

export class LoadNotification {
    static readonly type = '[Notification] Load Notification';
    constructor() {}
}

export class ReceiveNotification {
    static readonly type = '[Notification] Receive Notification';
    constructor(public notification) {}
}
