import { Selector, State, Action, StateContext } from '@ngxs/store';
import { ApiService } from 'src/app/shared/services/api.service';
import { LoadNotification,ReceiveNotification } from './shared.action';
import { Injectable } from '@angular/core';

export interface NotificationModel {
    notifications: Array<any>;
    total: number
}

@State<NotificationModel>({
    name: 'notification',
    defaults: {
        notifications: [],
        total: 0,
    }
})
@Injectable()
export class SharedState {
    constructor(public api: ApiService) { }

    @Selector()
    static AllNotification(state: NotificationModel) {
        return state;
    }

    @Action(LoadNotification)
    LoadNotification(ctx: StateContext<NotificationModel>) {
        this.api.request(
            'GET_NOTIFICATIONS',
            {
                params: {
                    PAGE_NO: 0,
                    PAGE_SIZE: 0
                }
            }).subscribe((res: any) => {
                if (res.result.length) {
                    ctx.setState({ notifications: res.result, total: res.total });
                } else {
                    ctx.setState({ notifications: [], total: 0 });
                }
            });
    }

    @Action(ReceiveNotification)
    ReciveNotification(ctx: StateContext<NotificationModel>, action: ReceiveNotification) {
        const state = ctx.getState();
        ctx.patchState({
            ...state,
            notifications: [action.notification, ...state.notifications],
        });
        const total = ctx.getState().notifications.filter(n => n.isRead === false).length;
        ctx.patchState({
            total: total,
        });
    }

}
