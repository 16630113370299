<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center" style="font-size:22px;padding-bottom: 10px;" class="border">
    <div> <strong class="header-text">{{title}}</strong></div>
    <div>
      <mat-icon class="info" *ngIf="data.type == 'Info'">info</mat-icon>
      <mat-icon color="warn" *ngIf="data.type == 'Confirm'">report_problem </mat-icon>
    </div>
  </div>

  <div style="text-align: start;padding-top: 10px;font-size: 15px;">
    {{data.message}}
  </div>
  <div fxLayout="row" fxLayoutAlign="end center">
    <mat-dialog-actions >
      <div *ngIf="data.type === 'Confirm'" style="padding-top: 15px;"> <button mat-button [mat-dialog-close]="true" style="background-color: #FFE9ED;">CONFIRM</button>
        <button mat-button mat-dialog-close>CANCEL</button>
      </div>
      <div *ngIf="data.type === 'Info'"> <button mat-button class="info" [mat-dialog-close]="true">OK</button></div>
    </mat-dialog-actions>
  </div>
</div>