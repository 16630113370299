import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { CompanyCreateComponent } from './company-create/company-create.component';

@NgModule({
    declarations: [UserProfileComponent, ChangePasswordComponent, UserCreateComponent, CompanyCreateComponent],
    imports: [
        CommonModule,
        UserRoutingModule,
        SharedModule
    ]
})
export class UserModule { }
