export  class LoadOrders {
    static readonly type = '[Orders] Load Orders';
    constructor(public req:any){}
}

export class LoadOrderLookupList {
    static readonly type = '[Order] Load Lookup List';
    constructor(){};
}

export class ReloadOrders {
    static readonly type = '[Order] Reload Order';
    constructor(){};
}

export class ClearOrders {
    static readonly type = '[Order] Clear Orders';
    constructor(){};
}

