import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/services/api.service';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/shared/services/toast.service';
import { LoadActiveUser } from '../../account/state/account.actions';
import { AuthUserService } from 'src/app/shared/services/auth-user.service';
import { CompanyCreateComponent } from '../company-create/company-create.component';
import { Observable } from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  subTitles = [
    { text: 'Dashboard', route: 'dashboard' },
    { text: 'Profile', 'route': '' }
  ];

  user: any;
  file: any;
  userForm: UntypedFormGroup;
  userName = '';
  profilePictureUrl = 'assets/images/profile-pic.png';
  disableSubmit = false;
  bypassUserMessage = "";
  isBypass = false;
  selectable = true;
  removable = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  CompanyCtrl = new UntypedFormControl();
  filteredCompanies: Observable<any[]>;
  selectedCompanies = [];
  allCompanies = [];
  @ViewChild('companyInput') companyInput: ElementRef<HTMLInputElement>;
  
  constructor(private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private dialog: MatDialog,
    private auth: AuthUserService,
    private store: Store,
    private toastService: ToastService) {}

  ngOnInit() {
    this.userForm = this.initForm(this.formBuilder);
    this.user = this.auth.getUser();
    if (this.user) {
      this.userForm.patchValue(this.user);
       this.isBypass = this.user.isByPass;
    var no = this.removeExtensions(this.user.phone);
      this.userName = this.user.firstName + ' ' + this.user.lastName;
      this.profilePictureUrl = this.user.isProfilePic ? `${environment.baseUrl}/api/Users/${this.user.id}/picture` :
      this.profilePictureUrl;
      if(this.user.printerPlanContactNo){
        this.disableSubmit = true;
      }

      this.userForm.controls.phone.setValue(no);
    }

    
    this.getCompaniesOfUser();
    this.filteredCompanies = this.CompanyCtrl.valueChanges.pipe(
      startWith(null),
      map((company: string | null) =>{
        return company ? this._filterc(company) : this.allCompanies.slice();
      } ));
  }

  initForm = (formBuilder: UntypedFormBuilder): UntypedFormGroup =>
  formBuilder.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    phone: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
    email: [''],
    enableBypassPaymentFeature : [false]
  })

  hasError = (field: string, errorName: string): boolean =>
  this.userForm.get(field).errors ? this.userForm.get(field).errors[errorName] : false

  onSelectFile = event => {
    if (event.target.files && event.target.files[0]) {
      this.loadUrl(event.target.files[0]);
    }
  }
  public loadUrl = (file: any) => {
    this.file = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      this.profilePictureUrl = event.target['result'];
      this.uploadeProfilePic(file);
    };
  }

  uploadeProfilePic(file: any) {
    const formdata = new FormData();
    formdata.append('file', file);
    const apiRequest = {
      params: { ID : this.user.id },
      data: formdata
    };

    this.apiService
      .request(
        'SET_PROFILE_PIC'
        , apiRequest)
      .subscribe((res: any) => {
        this.toastService.show('Profile picture is update successfully.');
        this.user.isProfilePicture = true;
        this.auth.saveUser(this.user);
      });
  }

  
  onSubmitClick() {
    if (this.userForm.invalid) {
      return;
    }

    var companyIds = this.selectedCompanies.map(company => company.id);

    const user = {
      id: this.user.id,
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      phone: this.userForm.value.phone,
      enableBypassPaymentFeature : this.userForm.value.enableBypassPaymentFeature,
      companyIds : companyIds
    };

    const apiRequest = {
      params: { ID: this.user.id },
      data: user
    };

    this.apiService.request('UPDATE_PROFILE', apiRequest).subscribe((res: any) => {
      if (res) {
        this.user.firstName = user.firstName;
        this.user.lastName = user.lastName;
        this.user.phone = user.phone;
        this.auth.saveUser(this.user);
        this.store.dispatch(new LoadActiveUser());
        this.toastService.show('Profile is update successfully.');
      }
    });
  }

  removeExtensions(phone){
    var phonenumber = phone.toLowerCase().split("ext");
    var no ;

    if (phonenumber.length == 1) {
      phonenumber = phone.toLowerCase().split("x");
      if (phonenumber.lenght != 1) {
       no = phonenumber[0];
      }
    } else {
       no = phonenumber[0];
    }
    return no;
  }

  changePassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '500px',
      data: {
        userId: this.user.id,
        title: 'Change password',
        showCloseBtn: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  openCreateCompanyDialog(){
    const dialogRef = this.dialog.open(CompanyCreateComponent, {
      width: '70vw',
    });

    dialogRef.afterClosed().subscribe(res => {
      if(res != null && res != undefined){
        this.selectedCompanies.push(res);
        this.allCompanies.push(res);
        this.apiService.request('ME').subscribe(response => {
          this.auth.saveUser(response['result']);
        });
      }
    });
  }


  //Company DD Functions
  loadCompanies() {
    const params = {
      PAGE_NO: 0,
      PAGE_SIZE: 0,
      COMPANY_NAME : ''
    }

    this.apiService.request('GET_COMPANIES', { params: params }).subscribe(res => {
      this.allCompanies = res['result'];
      // let selectedCompanyIds = this.userForm.value.companyIds;
      // if(Array.isArray(selectedCompanyIds)){
      //   if(selectedCompanyIds.length > 0){
      //     selectedCompanyIds.forEach(cid => {
      //       let index = this.allCompanies.findIndex(o => o.id == cid);
      //       if(index >= 0){
      //         this.selectedCompanies.push(this.allCompanies[index]);
      //       }
      //     });
      //   }
      // }
    });
  }

  remove(company): void {
    const index = this.selectedCompanies.findIndex(o => o.id == company.id);

    if (index >= 0) {
      this.selectedCompanies.splice(index, 1);
    }
  }

  selectCompany(event: MatAutocompleteSelectedEvent): void {
    let value = event.option.value;
    var i = this.allCompanies.findIndex(o => o.id == value);
    if(i >= 0){
      let j = this.selectedCompanies.findIndex(o => o.id == value);
      if(j < 0){
        this.selectedCompanies.push(this.allCompanies[i]);
      }
    }
    this.companyInput.nativeElement.value = '';
    this.CompanyCtrl.setValue(null);
  }

  showAll(){
    this.CompanyCtrl.setValue(this.companyInput.nativeElement.value);
  }

  private _filterc(value): any[] {
    const filterValue = value.toString().toLowerCase();
    return this.allCompanies.filter(c => c.name.toLowerCase().includes(filterValue));
  }

  getCompaniesOfUser(){
    this.apiService.request('GET_COMPANIES_OF_USER').subscribe((res) => {
      this.selectedCompanies = res['result'];
      if(this.selectedCompanies){
        if(this.selectedCompanies.filter(c => c.isBypassPayment).length > 1){
          this.bypassUserMessage = `Allow orders belonging to ${this.selectedCompanies.filter(c => c.isBypassPayment).map(x => x.name).join(",")} companies to bypass the payment process`;
        }

        if(this.selectedCompanies.filter(c => c.isBypassPayment).length == 1){
          this.bypassUserMessage = `Allow orders belonging to ${this.selectedCompanies.filter(c => c.isBypassPayment).map(x => x.name).join(",")} company to bypass the payment process`;
        }        
      }
      this.loadCompanies();
    });
  }
}