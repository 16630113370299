<div fxLayout="row">
    <app-breadcrumb title="Profile" [subTitles]="subTitles"></app-breadcrumb>
    <span fxFlex></span>
</div>

<br>

<div fxLayout="row" class="user-profile">

    <!-- Profile picture and change password -->
    <div fxLayout="column" fxFlex="300px" class="avatar mat-elevation-z1">
        <div fxLayoutAlign="center center" fxFlexOffset="10" style="position: relative;">
            <img [src]="profilePictureUrl">
            <mat-icon color="primary" matTooltip="Change profile picture" (click)="fileInput.click()">camera_alt
            </mat-icon>
            <input type="file" #fileInput accept=".png, .jpg, .jpeg" (change)="onSelectFile($event)" />
        </div>

        <div class="user-name" fxLayoutAlign="center center">
            <h3>{{user.firstName | titlecase}} {{user.lastName| titlecase}} </h3>
        </div>

        <mat-divider></mat-divider>

        <div fxLayout="column" fxFlex="100px" fxLayoutAlign="center center">
            <button mat-raised-button (click)="changePassword()">CHANGE PASSWORD</button>
        </div>
    </div>

    <!-- Update the detail of user -->
    <div fxFlex="100" fxLayoutAlign="center center">
        <form [formGroup]="userForm" fxLayout="column" autocomplete="off" fxFlex="75" style="padding: 10px; " fxLayoutGap="8px">
            <div fxLayout="row" fxLayoutGap="2%">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>First Name</mat-label>
                    <input matInput type="text" autofocus="true" placeholder="First Name" formControlName="firstName"
                        required [readonly]="disableSubmit" [ngClass]="{'cursor-not-allowed': disableSubmit === true}"/>
                    <mat-error *ngIf="hasError('firstName','required')">You must enter first name.</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Last Name</mat-label>
                    <input matInput type="text" placeholder="Last Name" formControlName="lastName" required [readonly]="disableSubmit" [ngClass]="{'cursor-not-allowed': disableSubmit === true}"/>
                    <mat-error *ngIf="hasError('lastName','required')">You must enter last name.</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="2%">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" placeholder="Email" formControlName="email" readonly [ngClass]="{'cursor-not-allowed': disableSubmit === true}"/>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Phone</mat-label>
                    <input matInput type="text" placeholder="Phone"  minlength="10" maxlength="10" pattern="^[0-9]*$"
                        formControlName="phone" required [readonly]="disableSubmit" [ngClass]="{'cursor-not-allowed': disableSubmit === true}"/>
                    <mat-error *ngIf="hasError('phone','required')">You must enter phone number.</mat-error>
                    <mat-error *ngIf="hasError('phone','pattern')">You must enter valid phone number.</mat-error>
                    <mat-error *ngIf=" !hasError('phone','pattern') && hasError('phone','minlength')">You must enter 
                        valid phone
                        number.</mat-error>
                </mat-form-field>
            </div>
            <!-- <div fxLayout="row" fxLayoutAlign="end center">
                <button  mat-raised-button color="primary" (click)="openCreateCompanyDialog()">Add Company</button>
            </div> -->
            <div fxLayout="row" fxLayoutGap="2%">
                <mat-form-field appearance="outline" fxFlex="100" autocomplete="off">
                  <mat-label>Company</mat-label>
                <mat-chip-list #companyChipList aria-label="Company selection" style="border: black;">
                    <mat-chip *ngFor="let sc of selectedCompanies" [selectable]="selectable" [removable]="removable"
                        (removed)="remove(sc)">
                        {{sc.name}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="Company" #companyInput [formControl]="CompanyCtrl" 
                        [matChipInputFor]="companyChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (focus)="showAll()"
                        autocomplete="off" [readonly]="disableSubmit" [ngClass]="{'cursor-not-allowed': disableSubmit === true}">
                </mat-chip-list>
                  <!-- <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectCompany($event)">
                    <mat-option *ngFor="let fc of filteredCompanies | async" [value]="fc.id">
                      {{fc.name}}
                    </mat-option>
                  </mat-autocomplete> -->
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="2%" >
                <mat-checkbox color="primary" labelPosition="after" disabled="true" formControlName="bypassUser" [checked]= "user.enableBypassPaymentFeature">
                    <small> Allow orders belonging to the customer to bypass the payment process </small>
                </mat-checkbox>
            </div>

            <div fxLayout="row" fxLayoutGap="2%" *ngIf="bypassUserMessage">
                <mat-checkbox color="primary" labelPosition="after" disabled="true" formControlName="bypass" [checked]= "bypassUserMessage">
                    <small> {{bypassUserMessage}}</small>
                </mat-checkbox>
            </div>


            <div fxLayout="row" fxLayoutAlign="center" *ngIf="!disableSubmit">
                <button fxFlex="100px" mat-raised-button type="submit" (click)="onSubmitClick()">UPDATE</button>
            </div>

        </form>
    </div>
</div>