<div fxLayout="row" matRipple [matMenuTriggerFor]="userMenu">
  <div fxLayout="row" fxLayoutAlign="space-between end" class="user-info">
      <mat-icon class="user-icon">perm_identity</mat-icon>
    <!-- <span   matTooltip="{{userName| titlecase}}" >{{userName| titlecase}}</span> -->
    <span matTooltip="{{firstName | titlecase}} {{lastName | titlecase}}">{{firstName | titlecase}} {{lastName | titlecase}}</span>
  </div>
  <mat-icon>arrow_drop_down</mat-icon>
</div>

<mat-menu #userMenu="matMenu" xPosition="before">
  <button mat-menu-item (click)="OnDashboardClick()">
    <mat-icon>dashboard</mat-icon>
    <span>Dashboard</span>
  </button>
  <button mat-menu-item (click)="OnProfileClick()">
    <mat-icon>person</mat-icon>
    <span>Profile</span>
  </button>
  <button mat-menu-item (click)="OnAddressClick()">
    <mat-icon>chrome_reader_mode</mat-icon>
    <span>Address</span>
  </button>
  <button routerLinkActive="active" mat-menu-item (click)="signOff()">
    <mat-icon>logout</mat-icon>
    <span>Sign Off</span>
  </button>
</mat-menu>