import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/services/api.service';
import { Store } from '@ngxs/store';
import { PasswordConfirm } from './PasswordConfirm';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  userForm: UntypedFormGroup;
  title = 'Create User';
  isShowPassword = false;
  isShowConfPassword = false;
  
  constructor(private dialig: MatDialog,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<UserCreateComponent>,
    private apiService: ApiService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public user: any) { }

  ngOnInit() {
    this.initPage();
  }

  initPage = () => {
    this.userForm = this.initForm(this.formBuilder);
      if (this.user) {
      this.title = 'Edit User';
      this.userForm.patchValue(this.user);
      this.userForm.controls.password.disable();
      this.userForm.controls.confirmPassword.disable();

     }
  }

  initForm = (formBuilder: UntypedFormBuilder): UntypedFormGroup =>
    formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      compnayName: [''],
      coWebsite: [''],
      password:['',[Validators.required , Validators.minLength]],
      confirmPassword:['',[Validators.required , Validators.minLength]]
    },
    {validator: PasswordConfirm.MatchPassword})

    hasError = (field: string, errorName: string): boolean =>
    this.userForm.get(field).errors ? this.userForm.get(field).errors[errorName] : false;

    onSubmit = () => {
      // validate form
      if(this.userForm.invalid){
        return;
      }
  
      // create request object
      let requestObject = {
        firstName:this.userForm.controls.firstName.value ,
        lastName:this.userForm.controls.lastName.value ,
        email:this.userForm.controls.email.value ,
        phone:this.userForm.controls.phone.value ,
        password:this.userForm.controls.password.value ,
        compnayName : this.userForm.controls.compnayName.value ,
        coWebsite : this.userForm.controls.coWebsite.value ,
      }
  
      if (!this.user) {
        this.createUser(requestObject);
        return;
      }
       this.editUser(requestObject);
    }

    createUser = (requestObject) => {
      this.apiService.request('CREATE_USER', { data: requestObject  }).subscribe((res: any) => {
        if (res) {
          this.dialogRef.close(true);
        }
      },error=>{
       // this.dialogRef.close(false);
      });
    }

    editUser = (requestObject) => {
      const apiRequest = {
        params: { ID: this.user.id },
        data: requestObject
      };

      // add user id in user object.
      apiRequest.data['Id']= this.user.id;

      this.apiService.request('EDIT_USER', apiRequest).subscribe((res: any) => {
        if (res) {
          this.dialogRef.close(true);
        }
      },error=>{
      // this.dialogRef.close(false);
      });
    }
    
}
