import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  subTitles: Array<{ text: string, route: string }>;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  navigate(route){
    if(route == ''){return;}
    this.router.navigateByUrl('/'+route);
  }

}
