import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { HTTPStatus } from 'src/app/shared/services/loading.interceptor';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ChangePasswordComponent } from 'src/app/features/user/change-password/change-password.component';
import { AuthUserService } from 'src/app/shared/services/auth-user.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit , OnDestroy{
  isLoading: boolean;
  httpStatusSubScription : Subscription;

  @ViewChild(MatProgressBar) progress: MatProgressBar;

  constructor(private httpStatus: HTTPStatus, 
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private userService:AuthUserService,
    private api:ApiService) { }
  
  
  ngOnDestroy(): void {
    this.httpStatusSubScription.unsubscribe();
  }

  ngOnInit() {
    this.getUser();
    this.httpStatusSubScription = this.httpStatus
      .getHttpStatus()
      .subscribe((status: boolean) => {
        setTimeout(() => {
          this.isLoading = status;
          this.ref.detectChanges();
        }, 10);
      });

    if (this.userService.getUser().isTempPassword) {
      this.changePassword();
    }

  }

  getUser(){
    this.api.request('ME').subscribe(res => {
      res['success']? this.userService.saveUser(res['result']):'';
    })
  }

  changePassword() {
    var user = this.userService.getUser();
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '500px',
      data: {userId:user.id,title:'Set password',showCloseBtn:false},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        user.isTempPassword = false;
        this.userService.saveUser(user);
      }
    });
  }
}