import { Directive, Output, HostBinding, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[pacificPrintingDragDrop]'
})
export class DragDropDirective {
  @Output() fileDropped = new EventEmitter<any>();
  
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();

  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileDropped.emit(evt.dataTransfer.files);
  }

}
