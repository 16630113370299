import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { TopnavComponent } from './topnav/topnav.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { ChangePasswordComponent } from 'src/app/features/user/change-password/change-password.component';
import { UserModule } from 'src/app/features/user/user.module';
import { CompanyComponent } from './company/company.component';

@NgModule({
    declarations: [LayoutComponent, TopnavComponent, HeaderComponent, CompanyComponent],
    imports: [
        CommonModule,
        SharedModule,
        UserModule,
        RouterModule.forChild([]),
    ]
})
export class LayoutModule { }
