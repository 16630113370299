import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import { UtcFormatterPipe } from './pipe/utc-formatepipe.pipe';
import { DragDropDirective } from './directive/drag-drop.directive';
import { NgxsModule } from '@ngxs/store';
import { SharedState } from './state/shared.state';
import { ExcelPreviewComponent } from './components/excel-preview/excel-preview.component';
import { SvgPreviewComponent } from './components/svg-preview/svg-preview.component';
import { TiffPreviewComponent } from './components/tiff-preview/tiff-preview.component';
import {MatDividerModule} from '@angular/material/divider';

const materialModules = [
  MatToolbarModule,
  MatButtonModule,
  MatListModule,
  MatFormFieldModule,
  MatTableModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatIconModule,
  MatDialogModule,
  MatInputModule,
  MatAutocompleteModule,
  MatMenuModule,
  MatTabsModule,
  MatCardModule,
  MatExpansionModule,
  MatBadgeModule,
  MatSidenavModule,
  MatSelectModule,
  MatExpansionModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatProgressBarModule,
  MatChipsModule,
  MatDatepickerModule,
  MatMomentDateModule,
  MatNativeDateModule,
  MatButtonToggleModule,
  MatStepperModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatBadgeModule,
  MatDividerModule
];

@NgModule({
  declarations: [BreadcrumbComponent, ConfirmDialogComponent,UserAvatarComponent, UtcFormatterPipe, DragDropDirective, ExcelPreviewComponent, SvgPreviewComponent, TiffPreviewComponent],
  imports: [
    CommonModule,
    ...materialModules,
    FlexLayoutModule,
    FormsModule ,
    NgxsModule.forFeature([SharedState]),
  ],
 
  exports:[
    ...materialModules,
    PerfectScrollbarModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    MatDividerModule,
    BreadcrumbComponent,
    ConfirmDialogComponent,
    UserAvatarComponent,
    UtcFormatterPipe,
    DragDropDirective,
    ExcelPreviewComponent,
    SvgPreviewComponent,
    TiffPreviewComponent
  ],
})
export class SharedModule { }
