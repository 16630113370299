import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthUserService } from 'src/app/shared/services/auth-user.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { PasswordConfirm } from '../user-create/PasswordConfirm';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form: UntypedFormGroup;
  title = 'Change Password';
  isShowPassword = false;
  isShowConfPassword = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private apiService: ApiService,
    private toast: ToastService,
    private userService:AuthUserService) { }

  ngOnInit() {
    this.form = this.initForm(this.formBuilder);
  }

  initForm = (formBuilder: UntypedFormBuilder): UntypedFormGroup =>
    formBuilder.group({
      password: ['', [Validators.required, Validators.minLength]],
      confirmPassword: ['', [Validators.required, Validators.minLength]]
    },
      { validator: PasswordConfirm.MatchPassword }
    )

  hasError = (field: string, errorName: string): boolean =>
    this.form.get(field).errors ? this.form.get(field).errors[errorName] : false;

  submit() {
    if (this.form.invalid) {
      return;
    }

    const requestObject = {
      id: this.data.userId,
      password: this.form.value.password
    }
    this.apiService.request(
      'CHANGE_PASSWORD', { 
        params:{ID:this.data.userId},
        data: requestObject })
      .subscribe(result => {
        this.dialogRef.close();
        let user = this.userService.getUser();
        user.isTempPassword = false;
        this.userService.saveUser(user);
        this.toast.show(result['message'])
      });
  }
}
