import { Injectable } from '@angular/core';
import { User, UserFilter } from '../interfaces/user';

const STORAGE_KEY = 'pacific_printing_auth_key';

@Injectable({
  providedIn: 'root'
})
export class AuthUserService {

  constructor() { }

  saveUser(user: User): void {
    if(user.filter){
      let filter: UserFilter = {
        status: user.filter['Status'],
        orderNo: user.filter['OrderIds'],
        orderTitle: user.filter['OrderTitle'],
      };
      user.filter = filter
    }
    
    const userJson = JSON.stringify(user);
    localStorage.setItem(STORAGE_KEY, userJson);
  }

  getUser() {
    const userJson = localStorage.getItem(STORAGE_KEY);
    return <User>JSON.parse(userJson);
  }

  clear() {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem('token');
  }

  setUserFilters(filter){
    const userJson = <User>JSON.parse(localStorage.getItem(STORAGE_KEY));
    userJson.filter = filter;
    localStorage.setItem(STORAGE_KEY, JSON.stringify(userJson));
  }
}
