import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Injectable } from "@angular/core";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const urlsWithoutAuth = ['accounts/login','accounts/create','verification','accounts/forgot-password','accounts/share-password',,'accounts/share-otp','accounts/sso/login'];

    if(urlsWithoutAuth.filter(url => req.url.endsWith(url)).length > 0){
      return next.handle(req);
    }

    const token = localStorage.getItem('token');
    if (!token) {
      if(this.router.url.toLowerCase() != "/account/login"){
        localStorage.setItem("redirectUrl",this.router.url);
      }
      this.router.navigate(['/account/login']);
      return;
    }

    let request = req;
    if (token) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token)
      })
      request = cloned;
    }

    return next.handle(request)
      .pipe(tap((event: HttpEvent<any>) => {
        if (event && event['status'] === 204) {
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err && err['status'] === 401 && err.url.endsWith('/login') !== true) {
            localStorage.clear();
            this.router.navigate(['/account/login']);
          } else { }
        }
      }));
  }
}