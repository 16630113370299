<mat-toolbar fxFlex="100" fxLayout="column" fxLayoutAlign="space-between" class="header">
  <mat-toolbar-row >
    <div fxLayout="row" fxLayoutAlign="center center" [routerLink]="['/dashboard']" routerLinkActive="false">
      <img class="logo" src="./assets/images/logo_1.png" style="cursor: pointer;">
    </div>
  
    <div fxLayout="row" fxFlex fxLayoutAlign="end center" fxLayoutGap="5px">
      <app-company></app-company>
      <mat-menu #notificationMenu="matMenu" xPosition="before" backdropClass="customize" class="Bell">
  
        <ng-container *ngIf="notifications && notifications.length > 0 ;else elseNotificationBlock">
          <div fxLayout="row" fxLayoutAlign="space-between center" style="padding:10px; margin-top:5px">
            <span style="padding-left:10px; padding-bottom: 10px;width: 400 !important; " fxLayoutAlign="start center">
              <mat-icon > notifications </mat-icon> Notifications
            </span>
          </div>
          <mat-divider></mat-divider>
  
          <div fxLayout="column" >
            <perfect-scrollbar [scrollIndicators]="false" fxLayout="column" fxFlex="350px" >
              <div *ngFor="let notification of notifications">
                <button mat-menu-item class="notification"  (click)="readNotification(notification)">
                  <small class="message" [innerHtml]="notification.message" ></small>
                  <div fxLayout="row" style="margin-top: 6px;">
                    <span fxFlex></span>
                    <span><small>{{notification.createdAt | date}}</small></span>
                  </div>
                </button>
                <mat-divider></mat-divider>
              </div>
            </perfect-scrollbar>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="row" fxFlex fxLayoutAlign="center center">
            <button mat-flat-button style="margin-top:4px" (click)="readAllNotification()">Clear All
              Notifications</button>
          </div>
        </ng-container>
        <ng-template #elseNotificationBlock>
          <button mat-menu-item class="notification">
            <mat-icon>notifications_active</mat-icon>
            <span>Get notified when notification are available.</span>
          </button>
        </ng-template>
      </mat-menu>
  
      <button mat-icon-button [matMenuTriggerFor]="notificationMenu">
        <mat-icon [matBadgeHidden]="notificationCount === 0" [matBadge]="notificationCount" matBadgeColor="warn">
          notifications</mat-icon>
      </button>
      <div fxLayout="row">
        <div class="menu-divider"></div>
        <app-user-avatar></app-user-avatar>
      </div>
    </div>
  </mat-toolbar-row>

  <!-- <mat-toolbar-row>
    <button mat-button *ngFor="let menuItem of menuItems" [routerLink]="menuItem.route" [class.active]="isLinkActive(menuItem)" style="margin-right:5px;font-size: small;" [class.highlight]="menuItem.isAwaitingAppprovalTab && awaitingApprovalPaymentCount && awaitingApprovalPaymentCount > 0">
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
          <mat-icon>{{menuItem.icon}}</mat-icon>
          <span>{{menuItem.title | uppercase}}</span>
        </div> 
    </button>
  </mat-toolbar-row> -->

</mat-toolbar>

