<mat-toolbar class="header" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between">
    <div  fxLayout="row" fxLayoutAlign="center center"  [routerLink]="['/dashboard']" routerLinkActive="false"  >
        <img class="logo" src="./assets/images/logo_1.png">
      </div>
  <span fxFlex ></span>
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center" >
        <button *ngFor="let menuItem of menuItems"  
        mat-button [routerLink]="menuItem.route" routerLinkActive="active">
         <div fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="center center">
           <mat-icon>{{menuItem.icon}}</mat-icon>
           <span>{{menuItem.title | uppercase}}</span>
         </div>
       </button>

      </div>
     
    <div fxLayout="row" fxFlex fxLayoutAlign="end center" fxLayoutGap="30px" >
        <!-- <span matBadge="4" matBadgeColor="warn" matBadgeOverlap="false" fxLayoutAlign="end center">
            <mat-icon>notification_important</mat-icon>
        </span> -->

        <button mat-icon-button [matMenuTriggerFor]="notificationMenu">
          <mat-icon  [matBadge]="4" matBadgeColor="warn">
            notifications</mat-icon>
        </button>

        <mat-menu #notificationMenu="matMenu" xPosition="before">
              <button mat-menu-item class="notificationMenu">
                  <mat-icon>notifications_active</mat-icon>
                  <span>Notification are not available.</span>
              </button>
        </mat-menu>

        <div fxLayout="row">
            <div class="menu-divider"></div>
            <app-user-avatar></app-user-avatar>
        </div>
    </div>

</mat-toolbar>