import { AbstractControl } from '@angular/forms';
export class PasswordConfirm {

    static MatchPassword(AC: AbstractControl) {
        const password = AC.get('password').value;
        const verifyPassword = AC.get('confirmPassword').value;
        if (AC.get('confirmPassword').touched || AC.get('confirmPassword').dirty) {
            if (password !== verifyPassword) {
                AC.get('confirmPassword').setErrors( {MatchPassword: true} );
            } else {
                return null;
            }
        }
    }
}
