import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from './api.service';
import { AuthUserService } from './auth-user.service';

@Injectable({
    providedIn: 'root'
})
export class AwaitingApprovalPaymentHubService {

    private hubConnection: signalR.HubConnection;
    public awaitingApprovalPaymentCount = 0;
    public awaitingApprovalPaymentCountUpdated: Subject<number> = new Subject();

    constructor(private authService: AuthUserService,private apiService : ApiService) {}

    public startConnection = () => {
        const user = this.authService.getUser();  
        const url = environment.baseUrl + '/awaiting-approval-payment-Hub?userId=' + user.id;

        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .build();



        this.hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 10;
        this.addAwaitingApprovalPaymentCountEventListener();
        this.connect();
        this.hubConnection.onclose(_ => {
            this.connect();
        })
    }

    connect() {
        this.hubConnection
            .start()
            .then(() => {console.log('Connection started with Awaiting approval and payments hub');this.receiveCount();})
            .catch(err => {
                console.error('Error while starting connection: ' + err);
            });
    }

    public addAwaitingApprovalPaymentCountEventListener = () => {
        this.hubConnection.on('awaiting-approval-payment-count-changed', (data) => {
            this.awaitingApprovalPaymentCountUpdated.next(data);
        });
    }


    receiveCount(){
        setTimeout(() => {
            this.apiService.request('AWAITING_APPROVAL_PAYMENT_COUNT',{}).subscribe(res => {
                
            })
        },2000);
        
    }
}