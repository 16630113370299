import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment.prod';
import { LoadNotification, ReceiveNotification } from '../state/shared.action';
import { AuthUserService } from './auth-user.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationHubService {

  private hubConnection: signalR.HubConnection;
  constructor(private authService: AuthUserService,private store:Store) {}

  public startConnection = () => {
    const user = this.authService.getUser();  
    if (user) {
      const url = environment.baseUrl + '/notificationHub?userId=' + user.id;
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(url, { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets || signalR.HttpTransportType.LongPolling })
        .build();
      this.hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 10;

      this.hubConnection
        .start()
        .then(() => console.log('Connection started'))
        .catch(err => {
          console.error('Error while starting connection: ' + err);
        });

      this.receive();
      this.setread();
    }

  }

  send(notification: any) {
    this.hubConnection
      .invoke('send', notification )
      .then()
      .catch(err => console.error(err));
  }

  receive() {
    this.hubConnection.on('receive', (notification) => {
      this.store.dispatch(new ReceiveNotification(notification));
    });
  }

  setread(){
    this.hubConnection.on('setread',()=>{
      this.store.dispatch(new LoadNotification());
    });
  }

}
