import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  title ='Confirm your action';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
 
  ngOnInit() {
    if(this.data.type === 'Info'){
      this.title = 'Info'
    }

  }

}
