import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadOrderLookupList } from 'src/app/features/orders/state/order.action';
import { ApiService } from 'src/app/shared/services/api.service';
import { NotificationHubService } from 'src/app/shared/services/notification-hub.service';

import { LoadNotification } from 'src/app/shared/state/shared.action';
import { SharedState } from 'src/app/shared/state/shared.state';
import { Location } from '@angular/common';
import { AwaitingApprovalPaymentHubService } from 'src/app/shared/services/awaiting-approval-payment-hub.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})

export class TopnavComponent implements OnInit,OnDestroy {

  menuItems =[
  { title: 'Orders', icon: 'local_shipping', route: '/orders',isAwaitingAppprovalTab : false }
 ];
  stickyHeader = false;
  notifications: Array<any>;
  notificationCount = 0;
  awaitingApprovalPaymentCount = 0;
  awaitingApprovalPaymentCountUpdatedSubscription : Subscription= null;

  constructor(private store: Store,
  private notificationHub:NotificationHubService,
  private awaitingApprovalPaymentHub :  AwaitingApprovalPaymentHubService,
  private api:ApiService,
  private route:Router) { }

  ngOnInit() {
    this.notificationHub.startConnection();
    this.awaitingApprovalPaymentHub.startConnection();
    window.addEventListener('scroll', this.scroll, true);
    this.store.dispatch(new LoadOrderLookupList());
    this.store.dispatch(new LoadNotification());
    this.getNotfication();
    this.getUnseenOrderCount();
  }

  getUnseenOrderCount(){
    this.awaitingApprovalPaymentCountUpdatedSubscription = this.awaitingApprovalPaymentHub.awaitingApprovalPaymentCountUpdated.subscribe(data => {
      this.awaitingApprovalPaymentCount = data;
    })
  }


  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
    if(this.awaitingApprovalPaymentCountUpdatedSubscription){
      this.awaitingApprovalPaymentCountUpdatedSubscription.unsubscribe();
    }
  }

  scroll = (event: any): void => {
    if (event.srcElement) {
      const number = event.srcElement.scrollTop;
      this.stickyHeader = number >= 64;
    }
  }

  getNotfication() {
    this.store.select(SharedState.AllNotification).subscribe(n => {
      this.notifications = [];
      this.notificationCount = 0;
      if (n && n.notifications.length > 0) {
        this.notifications = n.notifications;
        this.notificationCount = n.total;
      }
    });
  }

  readNotification = (notification) => {
    if (!notification)
      return;
    this.api.request('READ_NOTIFICATION', { params: { NOTIFICATION_ID: notification.id } }).subscribe(res => {
      this.store.dispatch(new LoadNotification());
      if(notification.orderId > 0){
        this.route.navigate(['orders',notification.orderId,'detail']); 
      }
      if(notification.jobId > 0){
        this.route.navigate(['orders',notification.jobId,'printer-plan','details']); 
      }
    });
  }

  readAllNotification = () => {
    if (this.notificationCount !== 0) {
      this.api.request('READ_ALL_NOTIFICATIONS').subscribe(res => {
        this.store.dispatch(new LoadNotification());
      });
    }
  }

  isLinkActive(menu): boolean {
    const url = menu.route;
    return url === this.route.url;
  }
  
}
