import { environment } from 'src/environments/environment.prod';

const accountEndPoint = [
  {
    name: 'LOGIN',
    method: 'POST',
    url: '/api/accounts/login'
  },
  {
    name: 'CREATE_ACCOUNT',
    method: 'POST',
    url: '/api/accounts/create'
  },
  {
    name: 'VERIFICATION',
    method: 'PUT',
    url: '/api/accounts/[ID]/verification'
  },
  {
    name: 'SSO_LOGIN',
    method: 'POST',
    url: '/api/accounts/sso/login'
  },
  {
    name: 'SHARE_PASSWORD',
    method: 'POST',
    url: '/api/accounts/share-password'
  },
  {
    name: 'SHARE_OTP',
    method: 'POST',
    url: '/api/accounts/share-otp'
  },
  {
    name: 'CHANGE_PASSWORD',
    method: 'PUT',
    url: '/api/accounts/[ID]/password'
  },
  {
    name: 'FORGOT_PASSWORD',
    method: 'PUT',
    url: '/api/accounts/forgot-password'
  },
  {
    name: 'ME',
    method: 'GET',
    url: '/api/users/me'
  }
]

const userEndPoint = [

  {
    name: 'CREATE_USER',
    method: 'POST',
    url: '/api/users'
  },
  {
    name: 'UPDATE_PROFILE',
    method: 'PUT',
    url: '/api/users/[ID]'
  },
  {
    name: 'SET_PROFILE_PIC',
    method: 'PUT',
    url: '/api/users/[ID]/picture'
  },
];

const addressEndPoint = [
  {
    name: 'GET_ADDRESSES',
    method: 'GET',
    url: '/api/addresses'
  },
  {
    name: 'ADD_ADDRESS',
    method: 'POST',
    url: '/api/addresses'
  },
  {
    name: 'UPDATE_ADDRESS',
    method: 'PUT',
    url: '/api/addresses/[ID]'
  },
  {
    name: 'DELETE_ADDRESS',
    method: 'DELETE',
    url: '/api/addresses/[ID]/[TYPE]'
  },
  {
    name: 'SET_AS_DEFAULT_ADDRESS',
    method: 'PUT',
    url: '/api/addresses/[ID]/[TYPE]'
  }
];

const orderEndPoint = [
  {
    name: 'GET_ORDERS',
    method: 'POST',
    url: '/api/orders/filter'
  },
  {
    name: 'GET_CUSTOMER_ORDERS',
    method: 'POST',
    url: '/api/orders/get-customer-orders'
  },
  {
    name: 'GET_AWAITING_APPROVAL_AND_AWAITING_PAYMENT',
    method: 'POST',
    url: '/api/orders/awaiting-approval-payments'
  },
  {
    name: 'GET_ORDER',
    method: 'GET',
    url: '/api/orders/[ID]'
  },
  {
    name: 'CREATE_ORDER',
    method: 'POST',
    url: '/api/orders'
  },
  {
    name: 'UPDATE_ORDER',
    method: 'PUT',
    url: '/api/orders/[ID]'
  },
  {
    name: 'DELETE_ORDER',
    method: 'DELETE',
    url: '/api/orders/[ID]'
  },
  {
    name: 'CANCEL_ORDER',
    method: 'PUT',
    url: '/api/orders/[ID]/cancel'
  },
  {
    name:'GET_LOOKUP_LIST',
    method:'GET',
    url:'/api/orders/lookup-list'
  },
  {
    name:'CHANGE_ORDER_ITEM_STATUS',
    method:'PUT',
    url:'/api/orders/status'
  },
  {
    name:'GET_ORDER_ITEMS',
    method:'GET',
    url:'/api/orders/items?pageNo=[PAGE_NO]&pageSize=[PAGE_SIZE]&search=[SEARCH]'
  },
  {
    name:'GET_REORDER_ITEMS',
    method:'GET',
    url:'/api/orders/reorder-items?pageNo=[PAGE_NO]&pageSize=[PAGE_SIZE]&search=[SEARCH]'
  },
  {
    name:'UPDATE_ORDER_ADDRESSES',
    method:'PUT',
    url:'/api/orders/update-addresses'
  },
  {
    name:'AWAITING_APPROVAL_PAYMENT_COUNT',
    method:'GET',
    url:'/api/orders/awaiting-approval-payment-count'
  },
  {
    name:'GET_CUSTOMER_ORDER_FILTER_LOOK_UP_LIST',
    method:'POST',
    url:'/api/orders/customer-fiter-look-up-list'
  },
];

const documentEndPoint = [
  {
    name: 'ADD_DOCUMENTS',
    method: 'POST',
    url: '/api/documents'
  },
  {
    name:'GET_THUMBNAIL',
    method:'GET',
    url:'/api/documents/[ID]/thumbnail'
  },{
    name:'DELETE_DOCUMENT',
    method:'DELETE',
    url:'/api/documents/[ID]'
  },
  {
    name:'APPROVE_DOCUMENT',
    method:'PUT',
    url:'/api/documents/[ID]/approve'
  },
  {
    name:'REJECT_DOCUMENT',
    method:'PUT',
    url:'/api/documents/[ID]/reject'
  },
  {
    name:'CHECK_PREVIEW_AVAILABLE',
    method:'GET',
    url:'/api/documents/[DOCUMENT_ID]/preview'
  },
  {
    name:'GET_DOCUMENT',
    method:'GET',
    url:'/api/documents/[DOCUMENT_ID]'
  },
  {
    name : 'SET_DOCS_VIEWED_BY_CUSTOMER',
    method : 'POST',
    url : '/api/documents/set-is-viewed-by-customer'
  },
  {
    name : 'GET_ORDER_DOCUMENTS',
    method : 'GET',
    url : '/api/documents/order/[ORDER_ID]'
  }
];

const noteEndPoint = [
  {
    name: 'ADD_NOTE',
    method: 'POST',
    url: '/api/notes'
  },
  {
    name:'DELETE_NOTE',
    method:'DELETE',
    url:'/api/notes/[ID]'
  },
  {
    name : 'SET_NOTES_VIEWED_BY_CUSTOMER',
    method : 'POST',
    url : '/api/notes/set-is-viewed-by-customer'
  }
]

const notificationEndPoint = [
  {
    name: 'GET_NOTIFICATIONS',
    method: 'GET',
    url:'/api/notifications?pageNo=[PAGE_NO]&pageSize=[PAGE_SIZE]'
  },
  {
    name: 'READ_NOTIFICATION',
    method: 'put',
    url:'/api/notifications/[NOTIFICATION_ID]'
  },
  {
    name: 'READ_ALL_NOTIFICATIONS',
    method: 'put',
    url:'/api/notifications'
  },
];

const companiesEndPoints = [
  {
    name: 'GET_COMPANIES_OF_USER',
    method: 'GET',
    url: '/api/Companies/get-companies-of-user'
  },
  {
    name: 'GET_COMPANIES',
    method: 'GET',
    url: '/api/Companies'
  },
  {
    name: 'CREATE_COMPANIES',
    method: 'POST',
    url:'/api/companies'
  },
]

const payment = [
  {
    name: 'CHECKOUT',
    method: 'POST',
    url:'/api/payment/make-payment'
  },
  {
    name: 'GET_PAYMENT_HISTORY',
    method: 'GET',
    url:'/api/payment/payment-history?orderId=[ORDER_ID]'
  },
  {
    name : "CREATE_PAYMENT_INTENT",
    method : "POST",
    url : "/api/payment/create-payment-intent"
  },
  {
    name : "CREATE_CHECKOUT_SESSION",
    method : "POST",
    url : "/api/payment/create-checkout-session"
  },
  {
    name : "PUBLISH_KEY",
    method : "GET",
    url : "/api/payment/publish-key"
  },
  {
    name : "UPDATE_STATUS",
    method : "POST",
    url : "/api/payment/update-status"
  }
]

const invoiceEndPoints = [
  {
    name: 'GET_INVOICE_BY_ID',
    method: 'GET',
    url:'/api/invoice/[ID]'
  },
  {
    name: 'UPDATE_VIEW_INVOICE_FLAG',
    method: 'POST',
    url:'/api/invoice/update-view-invoice-flag'
  },
]

const printerPlanNotesEndPoint =[
  {
    name: 'ADD_PRINTER_PLAN_NOTE',
    method: 'POST',
    url: '/api/printerplannotes/create'
  },
  {
    name: 'DELETE_PRINTER_PLAN_NOTE',
    method: 'DELETE',
    url: '/api/printerplannotes/[ID]'
  },
  {
    name: 'SET_PRINTER_PLAN_NOTES_VIEWED_BY_CUSTOMER',
    method: 'POST',
    url: '/api/printerplannotes/set-is-viewed-by-customer'
  },
]

const printerPlanDocumentsEndPoints = [
  {
    name: 'ADD_PRINTER_PLAN_DOCUMENTS',
    method: 'POST',
    url:'/api/printerplandocument/create'
  },
  {
    name: 'DELETE_PRINTER_PLAN_DOCUMENT',
    method: 'DELETE',
    url: '/api/printerplandocument/[ID]'
  },
  {
    name: 'SET_PRINTER_PLAN_DOCUMENT_VIEWED_BY_CUSTOMER',
    method: 'POST',
    url: '/api/printerplandocument/set-is-viewed-by-customer'
  },
]

const printerPlanEndPoints = [
  {
    name: 'GET_PRINTER_PLAN_JOB_BY_JOBID',
    method: 'GET',
    url:'/api/printerplan/by-jobid/[JOB_ID]'
  },
]

export const ApiConfig = {
  baseUrl: environment.baseUrl,
  endpoints: [
    ...accountEndPoint,
    ...userEndPoint,
    ...addressEndPoint,
    ...orderEndPoint,
    ...documentEndPoint,
    ...noteEndPoint, 
    ...notificationEndPoint,
    ...companiesEndPoints,
    ...payment,
    ...invoiceEndPoints,
    ...printerPlanEndPoints,
    ...printerPlanNotesEndPoint,
    ...printerPlanDocumentsEndPoints
  ]
};
