import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';


@Pipe({
  name: 'utcFormatter'
})
export class UtcFormatterPipe implements PipeTransform {

  transform(value: any) {
    if (value) {
      const userZone = moment.tz.guess();
      return (moment(value).tz(userZone).format('DD/MM/YYYY') + ' ' + moment(value).tz(userZone).format('hh:mm a'));

    }
  }

}