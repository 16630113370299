<div fxLayout="column" fxLayoutGap="15px">

    <strong style="font-size: 1.4rem" >{{title}}</strong>
  
    <form [formGroup]="userForm" autocomplete="off">
      <div fxLayout="row" fxLayoutGap="2% grid">
        <mat-form-field appearance="outline" fxFlex="50">
          <mat-label>First Name</mat-label>
          <input matInput type="text" autofocus="true" placeholder="First Name" formControlName="firstName" required />
          <mat-error *ngIf="hasError('firstName','required')">You must enter first name.</mat-error>
        </mat-form-field>
  
        <mat-form-field appearance="outline" fxFlex="50">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" placeholder="Last Name" formControlName="lastName" required />
          <mat-error *ngIf="hasError('lastName','required')">You must enter last name.</mat-error>
        </mat-form-field>
      </div>
  
      <div fxLayout="row" fxLayoutGap="2% grid">
        <mat-form-field appearance="outline" fxFlex="50">
          <mat-label>Email</mat-label>
          <input matInput type="text" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
            formControlName="email" required />
          <mat-error *ngIf="hasError('email','required')">You must enter email.</mat-error>
          <mat-error *ngIf="hasError('email','pattern')">You must enter valid email.</mat-error>
        </mat-form-field>
  
        <mat-form-field appearance="outline" fxFlex="50">
          <mat-label>Phone</mat-label>
          <input matInput type="text" placeholder="Phone" minlength="10" maxlength="10" pattern="^[0-9]*$"
            formControlName="phone" required />
          <mat-error *ngIf="hasError('phone','required')">You must enter phone number.</mat-error>
          <mat-error *ngIf="hasError('phone','pattern')">You must enter valid phone number.</mat-error>
          <mat-error *ngIf=" !hasError('phone','pattern') && hasError('phone','minlength')">You must enter valid phone
            number.</mat-error>
        </mat-form-field>
      </div>
  
      <div *ngIf="!this.user" fxLayout="row" fxLayoutGap="2% grid">
        <mat-form-field appearance="outline" fxFlex="50">
          <mat-label>Password</mat-label>
          <input matInput minlength="6" [type]="!isShowPassword ? 'password' : 'text'" autocomplete="off"
            placeholder="Password" formControlName="password" required />
          <mat-icon matSuffix (click)="isShowPassword = !isShowPassword" class="material-icons gray eye-icon"
            style="cursor: pointer">
            {{isShowPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="hasError('password','required')">You must enter password.</mat-error>
          <mat-error *ngIf="hasError('password','minlength')">Password length should be 6 digit.</mat-error>
        </mat-form-field>
  
        <mat-form-field  appearance="outline" fxFlex="50">
          <mat-label>Confirm Password</mat-label>
          <input matInput minlength="6" [type]="!isShowConfPassword ? 'password' : 'text'" autocomplete="off"
            placeholder="Password" formControlName="confirmPassword" required />
          <mat-icon matSuffix (click)="isShowConfPassword = !isShowConfPassword" class="material-icons gray eye-icon"
            style="cursor: pointer">
            {{isShowConfPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="hasError('confirmPassword','required')">You must enter password.</mat-error>
          <mat-error *ngIf=" userForm.controls['confirmPassword'].errors?.MatchPassword">Password does not match
          </mat-error>
        </mat-form-field>
      </div>
  
      <div fxLayout="row" fxLayoutGap="2%">
        <mat-form-field appearance="outline" fxFlex="50">
            <mat-label>Company Name</mat-label>
            <input matInput type="text" autofocus="true" placeholder="Company Name"
                formControlName="compnayName"/>
            <mat-error *ngIf="hasError('compnayName','required')">You must enter company name.</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="50">
            <mat-label>Co.Website</mat-label>
            <input matInput type="text" placeholder="Co.Website" formControlName="coWebsite" />
            <mat-error *ngIf="hasError('coWebsite','required')">You must enter website.</mat-error>
        </mat-form-field>
    </div>
  
      <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="3%">
        <button mat-raised-button fxFlex="150px" (click)="onSubmit()">SUBMIT</button>
        <button mat-flat-button type="button" (click)="dialogRef.close()">CLOSE</button>
      </div>
  
    </form>
  </div>
  